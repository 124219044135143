import { Component, Inject, ViewEncapsulation, ViewChild, ElementRef, HostListener } from '@angular/core';

import { BaseComponent } from "../..";
import { RouteRedirector, RouteNames, ApplicationLifeCycleService } from "../../providers";
import { AccountDataStore } from "../../providers/accountData";
import { GlobalDataStore } from '../../providers/globalData';
import { ImageSets } from '../../../../shared/utils';

@Component({
  selector: 'top-header',
  templateUrl: './headerComponent.html'
})
export class HeaderComponent extends BaseComponent {

  public links: any[];
  public logoType: string;
  public imageSet: string;
  public maxWidth: string;
  public iconsImageSet: string;
  public showSecondLogo: boolean = false;
  public logoTypeSecondLogo: string;
  public imageSetSecondLogo: string;
  public maxWidthSecondLogo: string;

  public canViewHeader: boolean = false;

  @ViewChild('navbarToggler')
  public navbarTogglerElement: ElementRef;

  @ViewChild('navbarSupportedContent')
  public navbarElement: ElementRef;

  @ViewChild('topHeader')
  protected topHeader: ElementRef;

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    public applicationLifeCycleService: ApplicationLifeCycleService,
    public accountDataStore: AccountDataStore
  ) {
    super();
  }

  ngOnInit() {

    // Read logo type & image set.
    this.logoType = this.uiSettings.appHeader.logoType;    
    this.imageSet = this.uiSettings.appHeader.imageSet;
    this.maxWidth = this.uiSettings.appHeader.maxWidth;

    this.showSecondLogo = this.uiSettings.appHeader.showSecondLogo;
    this.logoTypeSecondLogo = this.uiSettings.appHeader.logoTypeSecondLogo;
    this.imageSetSecondLogo = this.uiSettings.appHeader.imageSetSecondLogo;
    this.maxWidthSecondLogo = this.uiSettings.appHeader.maxWidthSecondLogo;

    this.iconsImageSet = this.getImageSet(ImageSets.MainMenu);

    this.canViewHeader = false;
    if (this.accountDataStore && this.accountDataStore.getUser()) {
      this.canViewHeader = this.accountDataStore.getUser().systemAuthorization.canViewWebAppHeader;
    } 

    if (this.canViewHeader) {

      this.populateLinks();
      // On user update we need to re-populate the links, so that localization changes take effect
      this.applicationLifeCycleService.onUserUpdated((user) => {

        this.populateLinks();

      });

    }
  }

  public populateLinks() {

    this.links = [];

    if (!this.visible || !this.accountDataStore.getUser().systemAuthorization.canViewWebAppHeader)
      return;

    if (this.globalDataStore.getGlobalData().globalSettings.webMainMenuStart)
      this.links.push({
        routerLink: "start",
        routerLinkActive: 'active',
        imageName: 'home',
        imageType: 'svg',
        text: this.strings.Home,
        tooltip: ''
      });

    if (this.globalDataStore.getGlobalData().globalSettings.webMainMenuCreate)
      this.links.push({
        routerLink: "selector",
        routerLinkActive: 'active',
        imageName: 'create',
        imageType: 'svg',
        text: this.strings.Create,
        tooltip: ''
      });

    if (this.globalDataStore.getGlobalData().globalSettings.webMainMenuSearch)
      this.links.push({
        routerLink: "search",
        routerLinkActive: 'active',
        imageName: 'search',
        imageType: 'svg',
        text: this.strings.Search,
        tooltip: ''
      });

    if (this.globalDataStore.getGlobalData().globalSettings.webMainMenuSettings)
      this.links.push({
        routerLink: "settings",
        routerLinkActive: 'active',
        imageName: 'setting',
        imageType: 'svg',
        text: this.strings.Settings,
        tooltip: ''
      });

    if (this.globalDataStore.getGlobalData().globalSettings.webMainMenuLogout)
      this.links.push({
        routerLink: "logout",
        routerLinkActive: 'active',
        imageName: 'logout',
        imageType: 'svg',
        text: this.strings.Logout,
        tooltip: ''
      });
  }

  public get visible(): boolean {        
    return this.accountDataStore.isUserLoggedIn();
  }

  public navigate(routerLink: string): boolean {

    if (!this.accountDataStore.getUser().systemAuthorization.canViewWebAppHeader) {
      this.routeRedirector.redirect([RouteNames.Logout]);
      return false;
    }

    // Close the navbar 
    this.closeNavbarMenu();

    this.routeRedirector.redirect([routerLink]);

    return false;
  }

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    // Close the menu if the click was outside top-header, but don't close it if the animation has not finished yet. (When the animation is running, it adds the class "collapsing")
    if (this.topHeader && !event.composedPath().includes(this.topHeader.nativeElement) && !this.navbarElement.nativeElement.className.includes("collapsing")) {
      this.closeNavbarMenu();
    }
  }

  public closeNavbarMenu(): void {
    this.navbarElement.nativeElement.className = this.navbarElement.nativeElement.className.replace("show", "");
    if (this.navbarTogglerElement && !this.navbarTogglerElement.nativeElement.className.includes("collapsed"))
      this.navbarTogglerElement.nativeElement.className += " collapsed";
  }
}